// ============================================================================
// App -- entry point for Vite JS
// ============================================================================

// Turbo - https://turbo.hotwired.dev/
// - Turbo Drive makes page loading async (activated by default when Turbo is loaded)
// - Turbo Frames decompose pages into independent contexts (not in use)
// - Turbo Streams deliver page changes over WebSocket, SSE or in response to form submissions (not in use)
import * as Turbo from "@hotwired/turbo";

// Stimulus - https://stimulus.hotwired.dev/
import { Application } from '@hotwired/stimulus';
import GlobalController from './controllers/global-controller';
const application = Application.start();
application.register('global', GlobalController);

// Focus Visible Polyfill
import 'focus-visible';

console.log("Hello!!");

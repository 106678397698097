// ============================================================================
// Stimulus controller: Global
// ============================================================================

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    initialize() {
        console.log("global-controller.js");
    }
}
